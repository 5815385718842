<template>
  <div class="mt-6">
    <p class="has-text-weight-bold">
      {{ $t("af:profile.dynamic_statements.title") }}
    </p>

    <p class="has-text-grey-icon">
      {{ $t("af:profile.dynamic_statements.subtitle") }}
    </p>

    <div
      class="setting-tile my-2"
      v-for="acceptance in dynamicStatementAcceptances"
      :key="acceptance.statementId"
    >
      <div class="is-flex">
        <b-checkbox
          class="af-checkbox"
          disabled
          :name="acceptance.statementIdentifier"
          :value="acceptance.isAccepted"
        />
        <div>
          <span class="is-flex">
            {{ acceptance.statementTitle }}
          </span>
          <span class="is-flex">
            {{ acceptance.statementLabel }}
          </span>
        </div>
        <div class="ml-auto">
          <span class="cursor-pointer" @click="downloadFile(acceptance)">
            <b-icon
              icon-pack="fa"
              icon="download"
              type="is-blue"
              class="mr-2"
            ></b-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileSaver from "file-saver";

export default {
  name: "UserDynamicStatements",
  props: {},
  async mounted() {
    await this.$store.dispatch("profile/fetchDynamicStatementAcceptances");
  },
  computed: {
    ...mapGetters({
      dynamicStatementAcceptances: "profile/getDynamicStatementAcceptances",
      getFile: "drawer/getFile",
    }),
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
    }),
    async downloadFile(acceptance) {
      await this.download(acceptance.documentId);
      let blob = this.getFile(acceptance.documentId);
      FileSaver.saveAs(blob, acceptance.fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.setting-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1rem;
}

.af-checkbox {
  vertical-align: middle;
}
</style>
